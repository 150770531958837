<template>
    <Section v-if="relatedPosts.length > 0" class="bg-white padding-top-0">
        <h2 class="section-header width-full text-left blue">{{ $translate('related_news', 'Relaterade Nyheter') }}</h2>
        <div class="width-full post-wrapper">
            <nuxt-link 
                v-for="post in relatedPosts" 
                :key="post.id" 
                :to="$prismic.linkResolver(post)" 
                class="br-5 border bxs text padding-4 black"
            >
                <div class="title-author">
                    <span class="text-bold green title">{{ post.data.title }}</span>
                    <span v-if="getAuthor(post)" class="text-bold">{{ getAuthor(post).data.name }}</span>
                </div>
                <p class="date">{{ getPublicationDate(post) }}</p>
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
import { replace } from '@swegaming-ab/nuxtjs-helpers';

export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            relatedPosts: [],
        };
    },
    async fetch() {
        const posts = await this.$prismic.api.query( 
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.fulltext('document', this.document.data.title.toLowerCase())
            ], 
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG,
                pageSize: 5
            }
        );
        this.relatedPosts = replace(posts.results);
    },
    computed: {
        authors() {
            return this.$store.state.authors;
        }
    },
    methods: {
        getPublicationDate(document) {
            return this.$format.date(document.last_publication_date);
        },
        getAuthor (document) {
            return this.authors[document.data.author.id];
        }
    }
};
</script>