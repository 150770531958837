<template>
    <table class="table">
        <tbody>
            <tr 
                v-for="(row, rowIndex) in tableData" 
                :key="rowIndex"
            >
                <th>{{ row.title }}</th>
                <td>
                    <span v-html="row.value" />
                    <tooltip 
                        v-if="row.showTooltip" 
                        class="margin-left-1" 
                        :tooltip-text="row.tooltipText" 
                    />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        tableData: {
            type: Array,
            required: true
        }
    }
};
</script>