<template>
    <Section
        width="md"
        :class="[paddingTop, paddingBottom, 'bg-' + data.primary.background]"
    >
        <CardCategory
            :id="data.primary.card.id"
            :category="data.primary.card_category"
            :category-text="data.primary.card_category_text"
            placement="highlighted"
            class="padding-0"
        />
    </Section>
</template>

<script>
import CardCategory from '@/components/cards/CardCategory.vue';
export default {
    components: { CardCategory },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        paddingTop(){
            return this.data.primary.remove_padding_top ? 'padding-top-0' : '';
        },

        paddingBottom () {
            return this.data.primary.remove_padding_bottom ? 'padding-bottom-0' : '';
        }
    }
};
</script>