<template>
    <Section
        class="compare-cards-slice padding-0 bg-white"
        width="lg"
    >
        <div class="table-overflow width-full border-top shadow-element">
            <table>
                <thead>
                    <tr>
                        <td />
                        <td v-for="(card, index) in cards" :key="index">
                            <nuxt-link :to="$prismic.linkResolver( card )">
                                <PrismicImage
                                    :img="card.data.image"
                                    class="margin-bottom-2 margin-top-4"
                                    w="75"
                                    h="50"
                                />
                            </nuxt-link>

                            <p class="text-bold">{{ card.data.core_api.brand_data.title }}</p>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(row, rowIndex) in tableRowData" :key="rowIndex">
                        <td>{{ row.header }}</td>
                        <td 
                            v-for="(cardCellData, index) in row.values" 
                            :key="index" 
                            :class="[{'highlight': cardCellData.isHighlighted}, cardCellData.customClass]" 
                        >
                            <span v-html="cardCellData.content" />
                            <tooltip 
                                v-if="cardCellData.showTooltip"
                                class="margin-left-1 black"
                                :tooltip-text="cardCellData.tooltipText" 
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_review', 'Recension') }}</td>
                        <td v-for="(card, index) in cards" :key="index">
                            <nuxt-link :to="$prismic.linkResolver(card)" class="green">
                                {{ $translate('button_read_review', 'Läs hela recensionen') }}
                            </nuxt-link>
                        </td>
                    </tr>

                    <tr>
                        <td />
                        <td v-for="(card, index) in cards" :key="index" class="padding-y-4">
                            <div class="flex">
                                <ButtonTarget
                                    :link="card"
                                    placement="comparison"
                                    :text="$translate('button_apply', 'Till ansökan')"
                                    background="orange"
                                    size="sm"
                                />
                            </div>
                            <div 
                                v-if="$validateText(card.model.complianceExample)" 
                                class="text-compliance"
                            >
                                {{ card.model.complianceExample }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            cards: [],
        };
    },
    computed: {
        lowest() {
            const lowest = {
                minInterestPossible: undefined,
                minEffectiveInterestPossible: undefined,
                minAge: undefined,
                overdraftFee: undefined,
                latePaymentFee: undefined,
                yearlyCost: undefined,
                penaltyInterest: undefined
            };
            const lowCheckers = Object.keys(lowest);
            let cardCategoryValues = [];

            lowCheckers.forEach(category => {
                this.cards.forEach(card => {
                    if (category === 'yearlyCost' && card.model.hasFirstYearOffer) {
                        cardCategoryValues.push(card.model.firstYearCost);
                    }
                    else {
                        cardCategoryValues.push(card.model[category]);
                    }
                });
                lowest[category] = Math.min(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return lowest;
        },
        highest () {
            const highest = {
                interestFreeDays: undefined,
                maxCredit: undefined
            };

            let cardCategoryValues = [];
            const highCheckers = Object.keys(highest);

            highCheckers.forEach(category => {
                this.cards.forEach(card => {
                    cardCategoryValues.push(card.model[category]);
                });
                highest[category] = Math.max(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return highest;
        },
        tableRowData() {
            return [
                {
                    header: this.$translate('yearly_fee', 'Årskostnad'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.yearlyCostHtml,
                            isHighlighted: this.checkLowest('yearlyCost', card),
                            showTooltip: card.model.hasFirstYearOffer,
                            tooltipText: this.$translate('card_info_yearly_fee_tooltip', '')
                        }
                    )),
                },
                {
                    header: this.$translate('card_info_highest_credit', 'Högsta kredit'),
                    values: this.cards.map(card => (
                        {
                            content: this.formatCreditData('maxCredit', card),
                            isHighlighted: this.checkHighest('maxCredit', card) && card.model.isCredit,
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_interest', 'Ränta'),
                    values: this.cards.map(card => (
                        {
                            content: this.formatCreditData('interestString', card),
                            isHighlighted: this.checkLowest('minInterestPossible', card) && card.model.isCredit,
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_effective_interest', 'Effektiv ränta'),
                    values: this.cards.map(card => (
                        {
                            content: this.formatCreditData('effectiveInterestString', card),
                            isHighlighted: this.checkLowest('minEffectiveInterestPossible', card) && card.model.isCredit,
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_interest_free', 'Räntefritt'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.getInterestFreeDaysString(this.$translate('bd_days', '{days} dagar')),
                            isHighlighted: this.checkHighest('interestFreeDays', card),
                        }
                    ))
                },
                {
                    header: this.$translate('cr_concierge_service', 'Concierge Service'),
                    values: this.cards.map(card => (
                        {
                            content: this.$formatBoolean(card.model.hasConciergeService),
                            isHighlighted: card.model.hasConciergeService,
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_card_type', 'Korttyp'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.cardType,
                            customClass: 'text-capitalize'
                        }
                    ))
                },
                {
                    header: this.$translate('compare_age_limit', 'Åldersgräns'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.minAge,
                            isHighlighted: this.checkLowest('minAge', card),
                        }
                    ))
                },
                {
                    header: this.$translate('compare_income_requirements', 'Inkomstkrav'),
                    values: this.cards.map(card => (
                        {
                            content: this.$formatBoolean(card.model.acceptsNoIncome),
                            isHighlighted: card.model.acceptsNoIncome,
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_currency_surcharge', 'Valutapåslag i utlandet'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.getCurrencyExchangeFeeString(this.$translate('bd_free', 'Gratis'), this.$translate('bd_currency_surcharge', '{percentage} (min {amount})", "{percentage} + {amount}")')),
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_withdrawal_fee', 'Uttagsavgift'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.getWithdrawalFeeString(this.$translate('bd_free', 'Gratis'), this.$translate('bd_withdrawal_fee', '{percentage} (min {amount})')),
                        }
                    ))
                },
                {
                    header: this.$translate('compare_min_amount_to_pay', 'Minsta belopp att betala'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.getInvoiceMinToPayString(this.$translate('bd_invoice_min', '{percentage} (min {amount})')),
                        }
                    ))
                },
                {
                    header: this.$translate('compare_payment_remarks', 'Betalningsanmärkningar'),
                    values: this.cards.map(card => (
                        {
                            content: this.$formatBoolean(card.model.acceptsPaymentRemarks),
                            isHighlighted: card.model.acceptsPaymentRemarks
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_avi_fee', 'Aviavgift'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.getAviFeeString(this.$translate('bd_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')),
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_late_fee', 'Förseningsavgift'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.latePaymentFeeString,
                            isHighlighted: this.checkLowest('latePaymentFee', card),
                        }
                    ))
                },
                {
                    header: this.$translate('compare_penalty_interest', 'Dröjsmålsränta'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.penaltyInterestString,
                            isHighlighted: this.checkLowest('penaltyInterest', card),
                        }
                    ))
                },
                {
                    header: this.$translate('compare_overdraft_fee', 'Övertrasseringsavgift'),
                    values: this.cards.map(card => (
                        {
                            content: card.model.overdraftFeeString,
                            isHighlighted: this.checkLowest('overdraftFee', card),
                        }
                    ))
                },
                {
                    header: this.$translate('card_info_extra_card', 'Extrakort'),
                    values: this.cards.map(card => (
                        {
                            content: this.$formatBoolean(card.model.hasFreeExtraCard),
                            isHighlighted: card.model.hasFreeExtraCard,
                        }
                    ))
                },
            ];
        },
    },
    mounted () {
        this.setCards();
    },
    methods: {
        cardModel (card) {
            return new this.$models.CreditCard(card, this.$store);
        },
        checkLowest (key, card) {
            if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                return card.model.firstYearCost <= this.lowest[key];
            }
            else if (key === 'minEffectiveInterestPossible' && card.model.isDebit) {
                return;
            }
            return card.model[key] <= this.lowest[key];
        },
        checkHighest (key, card) {
            return card.model[key] >= this.highest[key];
        },
        formatCreditData(key, card) {
            if (!card.model.isCredit) {
                return `<span class='padding-x-1 bg-blue white br-2'>${this.$translate('debit_card', 'Debitkort')}</span>`;
            }
            return key === 'maxCredit' 
                ? card.model.getMaxCreditString(this.$translate('bd_unlimited', 'Obegränsad'))
                : card.model[key];
        },
        setCards() {
            // this.cards = []

            // NOTE: For testing
            // let fakeArr = [ 'YGycrhMAACEAfuri', 'YHP1IxMAACMAoBZ2', 'YIAfcxAAACYAF9TU' ]
            // for(let i = 0; i < fakeArr.length; i++) {
            //     let id = fakeArr[i]
            //     this.cards.push(this.$store.state.cards[id])
            //     cardIds.push(id)
            //     let idString = Object.keys(cardIds).map(function(key) {
            //         return cardIds[key]
            //     }).join('&');
            // }


            this.$store.commit('CLEAR_CARDS_TO_COMPARE');

            const cards = this.$route.query.card;

            if (Array.isArray(cards)) {
                for (let i = 0; i < cards.length; i++) {
                    const card = Object.assign({}, this.$store.state.cards[cards[i]]);
                    card.model = new this.$models.CreditCard(card);
                    this.cards.push(card);
                }
            }

            else {
                const card = Object.assign({}, this.$store.state.cards[cards]);
                card.model = new this.$models.CreditCard(card);
                this.cards.push(card);
            }
        },
    },
};
</script>
