<template>
    <nuxt-link
        :to="$prismic.linkResolver(post)"
        class="post width-full border-bottom padding-bottom-6"
    >
        <prismic-image 
            v-if="post.data.image.url"
            :img="post.data.image"
            class="post-image"
            w="385"
            h="217"
        />
        <div class="post-preamble">
            <h3>{{ post.data.title }}</h3>
            <p>{{ preamble }}</p>
            <p class="margin-top-2 text-bold">{{ $format.date(post.first_publication_date) }}</p>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        post: {
            type: Object, 
            required: true
        }
    },
    computed: {
        text() {
            return this.post.data.content.filter(
                obj => Object.prototype.hasOwnProperty.call(obj, 'text')
            );
        },

        preamble () {

            if (this.text.length === 0) {
                return '';
            }

            let preamble = this.$prismic.asText(this.text.slice(0, 1));
            if (preamble.length > 300) {
                return preamble.substring(0, 300) + '...';
            }
            return preamble;
        }
    }
};
</script>
