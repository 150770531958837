<template>
    <div 
        v-if="$validateText(tooltipText)"
        v-tooltip="{
            content: tooltipText,
            trigger: 'manual',
            show: show
        }" 
        class="tooltip-wrapper"
        @mouseover="show = true"
        @click="show = true"
        @mouseleave="show = false"
    >
        <slot>
            <i class="fa-regular fa-info-circle" />  
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        tooltipText: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>

<style lang="scss" scoped>
    .tooltip-wrapper {
        display: inline-block;
    }
</style>
