<template>
    <div class="big-list-item">
        <div class="card-top-section">
            <h3 class="title">
                {{ item.h3 }}
            </h3>
            <nuxt-link :to="$prismic.linkResolver(item.card)">
                <PrismicImage
                    :img="item.card.data.image"
                    class="card-image"
                    w="160"
                />
            </nuxt-link>
            <div 
                v-if="$validateText(item.teaser)"    
                class="rich-text" 
                v-html="$prismic.asHtml(item.teaser)"
            />
        </div>

        <table-data 
            v-if="dataType === 'card-data'" 
            :table-data="item.tableData"    
        />

        <pros-list 
            v-else 
            :pros-list="item.prosList"    
        />
        <div class="button-container">
            <div v-if="outLinkEnabled" class="out-link-wrapper">
                <ButtonTarget
                    :link="item.card"
                    :text="$translate('button_apply', 'Till ansökan')"
                    icon="fas fa-lock white"
                    background="orange"
                    size="md"
                    class="card-out-link"
                />
            </div>
            <nuxt-link 
                :to="$prismic.linkResolver(item.card)"
                class="read-more-button btn btn-md btn-outline-blue"    
            >
                {{ $translate('button_read_more', 'Läs mer') }}
                <i class="fas fa-chevrons-right fa-sm" />
            </nuxt-link>
        </div>
        <div 
            v-if="$validateText(cardModel.complianceExample)"
            class="text-compliance"
        >
            {{ cardModel.complianceExample }}
        </div>
    </div>
</template>

<script>
import TableData from './TableData.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        TableData,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        dataType: {
            type: String,
            required: true
        }
    },
    computed: {
        cardModel () {
            return new this.$models.CreditCard(this.item.card, this.$store);
        },
        outLinkEnabled() {
            return this.item.card.type === 'lender' ? 
                Boolean(this.item.card.data.redirect) :
                this.item.card.data.redirect_enabled;
        }
    }
};
</script>